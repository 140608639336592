<template>
  <div class="container">
    <router-view />
  </div>
</template>

<script>
export default {
  methods: {
    appHeight() {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    },
  },
  mounted() {
    window.addEventListener("resize", this.appHeight);
    window.addEventListener("DOMContentLoaded", this.appHeight);
    this.appHeight();
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+KR:400,500&display=swap&subset=korean");
@import url("assets/fonts/gothamssm.css");

:root {
  --app-height: 100%;
}

html,
body {
  margin: 0;
  overflow: hidden;
}
body {
  width: 100vw;
  height: 100vh;
  height: var(--app-height);
  overflow: hidden;
  background-color: #f5f5f5;
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #000;
    height: 100%;
    .container {
      height: 100%;
    }
  }
}
a {
  text-decoration: none;
}
div,
img,
span {
  position: relative;
}
</style>
