import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  {
    path: "/guide/vr",
    name: "GuideVR",
    component: () => import(/* webpackChunkName: "guideVR" */ "../views/GuideVR.vue"),
  },
  {
    path: "/guide/style",
    name: "GuideStyle",
    component: () => import(/* webpackChunkName: "guideStyle" */ "../views/GuideStyle.vue"),
  },
  {
    path: "/style",
    name: "Style",
    component: () => import(/* webpackChunkName: "style" */ "../views/Style.vue"),
  },
  {
    path: "/vr-store",
    name: "VRStore",
    component: () => import(/* webpackChunkName: "VRStore" */ "../views/VRStore.vue"),
  },
  {
    path: "/notice",
    name: "Notice",
    component: () => import(/* webpackChunkName: "Notice" */ "../views/Notice.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
